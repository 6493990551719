import { FC, useMemo } from 'react';
import RadioButton from '../../shared/form-control/RadioButton';
import { Input, InputStyle } from '../../shared/form-control/Input';
import { DatePicker, DatePickerType } from '../../shared/form-control/DatePicker';
import DateUtils from '../../../utils/DateUtils';
import { useTranslation } from 'react-i18next';
import { DateInterval, DateIntervalKeys } from '../../../models/DateInterval';
import DropdownSelect, { DropdownSize } from '../../shared/form-control/DropdownSelect';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { DateFilterOptions, DateFilterValue } from '../../../models/TableViewFilters';
import { FilterProps } from './FilterProps';

const DateFilter: FC<FilterProps> = ({ columnConfig, selectedTemplateId }) => {
  const { t } = useTranslation(['table-view', 'common']);
  const intervals = DateUtils.dateIntervalOptions(t);
  const { filters, setFilters } = useTableViewFilters();

  const filterValue = useMemo(() => {
    return filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as DateFilterValue | undefined;
  }, [columnConfig.value, filters, selectedTemplateId]);

  const defaultMoreThan = useMemo(
    () => ({ value: filterValue?.moreThan?.value ?? 1, interval: filterValue?.moreThan?.interval ?? DateInterval.DAY }),
    [filterValue?.moreThan],
  );

  const defaultLessThan = useMemo(
    () => ({ value: filterValue?.lessThan?.value ?? 1, interval: filterValue?.lessThan?.interval ?? DateInterval.DAY }),
    [filterValue?.lessThan],
  );

  const defaultBetween = useMemo(
    () => ({
      start: filterValue?.between?.start ?? new Date().toISOString(),
      end: filterValue?.between?.end ?? new Date().toISOString(),
    }),
    [filterValue?.between],
  );

  const updateFilter = (selectedOption: DateFilterOptions, newValue: Partial<DateFilterValue> = {}) => {
    const updatedFilter: DateFilterValue = {
      selectedOption,
      moreThan: selectedOption === DateFilterOptions.MORE_THAN ? (newValue.moreThan ?? defaultMoreThan) : null,
      lessThan: selectedOption === DateFilterOptions.LESS_THAN ? (newValue.lessThan ?? defaultLessThan) : null,
      between: selectedOption === DateFilterOptions.BETWEEN ? (newValue.between ?? defaultBetween) : null,
    };

    setFilters(columnConfig, selectedTemplateId, updatedFilter);
  };

  return (
    <div className="h-72 w-72 space-y-4">
      <RadioButton
        value={filterValue?.selectedOption === DateFilterOptions.TODAY}
        size="small"
        label={t(`filters.date.${DateFilterOptions.TODAY}`)}
        onChange={() => updateFilter(DateFilterOptions.TODAY)}
      />
      <RadioButton
        value={filterValue?.selectedOption === DateFilterOptions.OVERDUE}
        size="small"
        label={t(`filters.date.${DateFilterOptions.OVERDUE}`)}
        onChange={() => updateFilter(DateFilterOptions.OVERDUE)}
      />
      <RadioButton
        value={filterValue?.selectedOption === DateFilterOptions.THIS_WEEK}
        size="small"
        label={t(`filters.date.${DateFilterOptions.THIS_WEEK}`)}
        onChange={() => updateFilter(DateFilterOptions.THIS_WEEK)}
      />
      <RadioButton
        value={filterValue?.selectedOption === DateFilterOptions.MORE_THAN}
        size="small"
        label={t(`filters.date.${DateFilterOptions.MORE_THAN}`)}
        onChange={() => updateFilter(DateFilterOptions.MORE_THAN, { moreThan: defaultMoreThan })}
      />
      {filterValue?.selectedOption === DateFilterOptions.MORE_THAN && (
        <div className="relative flex items-center space-x-2">
          <Input
            type="number"
            value={`${filterValue?.moreThan?.value ?? 1}`}
            onChange={(e) => updateFilter(DateFilterOptions.MORE_THAN, { moreThan: { ...defaultMoreThan, value: parseInt(e.target.value) } })}
            style={InputStyle.MINIMAL}
            wrapperClassName="w-28"
          />
          <DropdownSelect
            className="!w-[162px] text-left"
            value={{
              id: (filterValue?.moreThan?.interval ?? DateInterval.MONTH).toString(),
              value: filterValue?.moreThan?.interval ?? DateInterval.MONTH,
              text: t(DateIntervalKeys[filterValue?.moreThan?.interval ?? DateInterval.MONTH]),
            }}
            options={intervals}
            size={DropdownSize.S}
            onChange={(option) =>
              updateFilter(DateFilterOptions.MORE_THAN, { moreThan: { ...defaultMoreThan, interval: option.value as DateInterval } })
            }
            isFixed={false}
          />
        </div>
      )}
      <RadioButton
        value={filterValue?.selectedOption === DateFilterOptions.LESS_THAN}
        size="small"
        label={t(`filters.date.${DateFilterOptions.LESS_THAN}`)}
        onChange={() => updateFilter(DateFilterOptions.LESS_THAN, { lessThan: defaultLessThan })}
      />
      {filterValue?.selectedOption === DateFilterOptions.LESS_THAN && (
        <div className="relative flex items-center space-x-2">
          <Input
            type="number"
            value={`${filterValue?.lessThan?.value ?? 1}`}
            onChange={(e) => updateFilter(DateFilterOptions.LESS_THAN, { lessThan: { ...defaultLessThan, value: parseInt(e.target.value) } })}
            style={InputStyle.MINIMAL}
            wrapperClassName="w-28"
          />
          <DropdownSelect
            className="!w-[162px] text-left"
            value={{
              id: (filterValue?.lessThan?.interval ?? DateInterval.MONTH).toString(),
              value: filterValue?.lessThan?.interval ?? DateInterval.MONTH,
              text: t(DateIntervalKeys[filterValue?.lessThan?.interval ?? DateInterval.MONTH]),
            }}
            options={intervals}
            size={DropdownSize.S}
            onChange={(option) =>
              updateFilter(DateFilterOptions.LESS_THAN, { lessThan: { ...defaultLessThan, interval: option.value as DateInterval } })
            }
            isFixed={false}
          />
        </div>
      )}
      <RadioButton
        value={filterValue?.selectedOption === DateFilterOptions.BETWEEN}
        size="small"
        label={t(`filters.date.${DateFilterOptions.BETWEEN}`)}
        onChange={() => updateFilter(DateFilterOptions.BETWEEN, { between: defaultBetween })}
      />
      {filterValue?.selectedOption === DateFilterOptions.BETWEEN && (
        <div className="flex items-center space-x-2">
          <DatePicker
            date={filterValue?.between?.start ? new Date(filterValue.between.start) : new Date()}
            onChange={(date) => updateFilter(DateFilterOptions.BETWEEN, { between: { ...defaultBetween, start: date?.toISOString() ?? null } })}
            type={DatePickerType.INPUT}
            inputSize={InputStyle.MINIMAL}
            className="!w-32"
            notBefore={null}
            notAfter={filterValue?.between?.end ? new Date(filterValue.between.end) : new Date()}
          />
          <span>{t('filters.date.and')}</span>
          <DatePicker
            date={filterValue?.between?.end ? new Date(filterValue.between.end) : new Date()}
            onChange={(date) => updateFilter(DateFilterOptions.BETWEEN, { between: { ...defaultBetween, end: date?.toISOString() ?? null } })}
            type={DatePickerType.INPUT}
            inputSize={InputStyle.MINIMAL}
            className="!w-32"
            notBefore={filterValue?.between?.start ? new Date(filterValue.between.start) : null}
          />
        </div>
      )}
      <RadioButton
        value={filterValue?.selectedOption === DateFilterOptions.NOT_SET}
        size="small"
        label={t(`filters.date.${DateFilterOptions.NOT_SET}`)}
        onChange={() => updateFilter(DateFilterOptions.NOT_SET)}
      />
    </div>
  );
};

export default DateFilter;
